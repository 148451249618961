<template>
    <b-overlay :show="isLoadingHotelPerformance || isUpdatingHotelPerformance">
        <b-card>
            <div class="head-btn">
                <b-button
                    :to="{ name: 'hotel-performance'}"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                > <feather-icon icon="ArrowLeftIcon" style="color: white" />
                </b-button>
            </div>
            <div v-if="hotelPerformanceData">
                <ValidationObserver
                    ref="formUpdateHotelPerformance"
                    v-slot="{ invalid }"
                >
                    <b-form @submit.prevent="update">
                        <b-row>
                            <b-col md="6">
                                <b-row>
                                    <b-col md="">
                                        <ValidationProvider name="resort" rules="required">
                                            <b-form-group label="Resort" slot-scope="{ valid, errors }">
                                                <b-form-select
                                                    :state="errors[0] ? false : valid ? true : null"
                                                    v-model="hotelPerformanceData.resorts"
                                                >
                                                    <option value="">Seleccione Resort</option>
                                                    <option
                                                        v-for="hotel in hotels"
                                                        :key="hotel.id"
                                                        :value="hotel.id"
                                                    > {{ hotel.name }}
                                                    </option>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="">
                                        <ValidationProvider name="idioma" rules="required">
                                            <b-form-group label="Idioma*" slot-scope="{ valid, errors }">
                                                <b-form-select
                                                    :state="errors[0] ? false : valid ? true : null"
                                                    v-model="hotelPerformanceData.language"
                                                >
                                                    <option :value="null" disabled>Seleccione Idioma</option>
                                                    <option v-for="lang in languages" :key="lang.id" :value="lang.id">
                                                        {{ lang.name }}
                                                    </option>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>


                                    <b-col md="12" class="imgHotel">
                                        <ValidationProvider :rules="hotelPerformanceData.urlgraphic ? '' : 'required'" name="imagenn">
                                            <b-form-group
                                                label="Imagen principal"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <b-form-file
                                                    v-model="file"
                                                    @input="setImgFile(file)"
                                                    placeholder="Adjunte una imagen como evidencia..."
                                                    drop-placeholder="Drop file here..."
                                                    :state="errors[0] ? false : valid ? true : null"
                                                    browse-text="Buscar imagen"
                                                />
                                                <b-form-invalid-feedback>{{errors[0]? "Se debe adjuntar la evidencia para la autorización": ""}}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col class="label-text" md="12">
                                        <validation-provider name="enlace destino" rules="">
                                            <b-form-group slot-scope="{ valid, errors }">
                                                <label>Enlace destino</label>
                                                <b-form-input
                                                    v-model="hotelPerformanceData.link"
                                                    :state="errors[0] ? false : valid ? true : null"
                                                    placeholder="https://"
                                                />
                                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>


                                    <b-col md="">
                                        <div class="mt-1">
                                            <b-form-checkbox
                                                v-model="hotelPerformanceData.status"
                                                :value="true"
                                                class="custom-control-success"
                                            > Activado
                                            </b-form-checkbox>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col>
                                <b-img
                                    :src="sourceFile"
                                    class="product-img mb-1"
                                    height="225rem"
                                    @error="imgAlternativo"
                                />
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <div class="float-right">
                                    <b-button
                                        type="submit"
                                        class="btn-block"
                                        variant="primary"
                                        :disabled="invalid"
                                    > Actualizar
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-form>
                </ValidationObserver>
            </div>
            <b-alert show variant="danger" class="p-1 text-center" v-else>
                No se encontró información
            </b-alert>
        </b-card>

    </b-overlay>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { showAlertMessage, getLocalFilePath } from '@/helpers/helpers'

export default {
    directives: {Ripple, },
    async created() {
        await this.init()
    },
    data(){
        return {
            hotelPerformanceData: null,
            imgDefault: require("@/assets/images/default.jpg"),
            isLoadingHotelPerformance: false,
            isUpdatingHotelPerformance: false,
            file: null,
            imgUrl: process.env.VUE_APP_IMG_SRC_API,
        }
    },
    computed: {
        ...mapState('auth', ['user']),
    
        ...mapState('start', ['hotels','languages']),
        sourceFile(){
            if (this.file && typeof this.file != 'string') return getLocalFilePath(this.file)
            else return this.imgUrl +'/'+ this.hotelPerformanceData.urlgraphic
        },
    },
    methods: {
        ...mapActions('fivesClubCatalogs', ['getInitialContentForHotelPerformance','fetchHotelPerformanceById','updateHotelPerformance']),
        ...mapMutations('start',['setHotels', 'setLanguages']),


        async init(){
            const idHotelPerformance = this.$route.params.id
            this.isLoadingHotelPerformance = true
            this.hotelPerformanceData = null

            if( this.isANumber(idHotelPerformance) ){
                const { hotels, languages } = await this.getInitialContentForHotelPerformance()

                if( !this.hotels || this.hotels?.length === 0 ){
                    this.setHotels(hotels)
                }

                if( !this.languages.length ){
                    this.setLanguages(languages)
                }

                this.hotelPerformanceData = await this.fetchHotelPerformanceById(idHotelPerformance)
            }

            this.isLoadingHotelPerformance = false
        },
        isANumber(string){
            return string ? !isNaN( string ) && string > 0 : false
        },
        setImgFile(file) {
            if ( file ) this.file = file
            else {
                const text = `😪😪 Tiene que adjuntar un archivo de tipo imagen`;
                showAlertMessage( "No hay imagen", "InfoIcon", text, "danger", 4000, "bottom-right" )
            }
        },
        async update() {
            this.isUpdatingHotelPerformance = true;

            const payload = {
                idUser: this.user.idUser,
                id: this.hotelPerformanceData.id,
                file: this.file,
                language:  this.hotelPerformanceData.language,
                link:  this.hotelPerformanceData.link,
                status: Boolean(this.hotelPerformanceData.status),
            }
        
            const { status, message } = await this.updateHotelPerformance(payload); // updateWebProp in backend

            if (status) {
                showAlertMessage('Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
			    this.$router.push({ name: 'hotel-performance' })
            }

            this.isUpdatingHotelPerformance = false;
        },
        imgAlternativo(event) {
            event.target.src = this.imgDefault
        },
    },
};
</script>

<style scoped>
.head-btn{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}
</style>
